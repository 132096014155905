import axios from "axios"

const request = axios.create({
  baseURL: "https://api.innovaltd.tech",
  timeout: 30000,
  headers: {}
})

// 请求拦截
request.interceptors.request.use(function (config) {
  //授权Token
  // let token = localStorage.getItem("token")
  // config.headers["Authorization"] = "bearer " + token
  return config

}, function (error) {

  return Promise.reject(error)

});

export default request
