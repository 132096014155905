import React from "react";
import AppStateContext from "./context/app-state-context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ThemeContext from "./context/theme-context";
import Home from "./pages/home";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import "./index.css"

function App() {
  return (
    <BrowserRouter>
      <AppStateContext>
        <ThemeContext>
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/terms"} element={<Terms />} />
            <Route path={"/privacy"} element={<Privacy />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            limit={1}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick={true}
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
            closeButton={false}
            theme="light"
            transition={Slide} />
        </ThemeContext>
      </AppStateContext>
    </BrowserRouter>
  );
}

export default App;
