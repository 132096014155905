import React, { useState } from "react";
import styled from "styled-components"
import FlexBox from "../widget/FlexBox";
import { contactUs } from "../service/home";
import { toast } from "react-toastify";
import { useWindowSize } from "../hook/useWindowSize";

const overviewWidthRatio = 908 / 1920;
const overviewHeightWidthRatio = 899 / 908;

export default function Home() {
  const { width } = useWindowSize();
  const [formData, setFormData] = useState({
    email: "",
    content: "",
  });

  function isMobile() {
    return width < 1024;
  }

  function handleValueChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (formData.email.length === 0) {
      toast.warn("Please input email");
      return;
    }
    if (formData.content.length === 0) {
      toast.warn("Please input content");
      return;
    }

    toast.promise(
      contactUs(formData),
      {
        pending: "Loading...",
        success: {
          render({ data }) {
            const { code, message } = data.data;
            if (code === 0) {
              setFormData({ email: "", content: "" });
              return "Submitted";
            } else {
              return message;
            }
          }
        },
      }
    );
  }

  const overviewImgWidth = width * (isMobile() ? (overviewWidthRatio + 0.43) : overviewWidthRatio);
  const overviewImgHeight = overviewImgWidth * overviewHeightWidthRatio;

  return (
    <Root>
      <BarText isMobile={isMobile()}>Look at our new tool, Photo Optimizer</BarText>
      <TopLine />
      <FlexBox style={{ marginTop: isMobile() ? "50px" : "123px" }}>
        <TopLogo
          src={require("../assets/icon_logo.png")}
          isMobile={isMobile()} />
        <TopAppNameText isMobile={isMobile()}>INNOVA</TopAppNameText>
      </FlexBox>
      <BlurBox isMobile={isMobile()}>
        <BlurBg />
        <BlurContentBox>
          {
            isMobile() ?
              <TitleText isMobile={true}>
                Improve photo quality and find better moments
              </TitleText> :
              <TitleText isMobile={false}>
                Improve photo quality<br />
                and find better<br />
                moments
              </TitleText>
          }
          <IntroText isMobile={isMobile()}>
            Just choose Photo submission processing to get great photos easily
          </IntroText>
          <OverviewImg
            src={require("../assets/img_overview.png")}
            isMobile={isMobile()}
            style={{
              width: overviewImgWidth + "px",
              height: overviewImgHeight + "px",
              marginTop: isMobile() ? "50px" : "110px",
              marginRight: "auto",
              marginBottom: isMobile() ? "30px" : "73px",
              marginLeft: "auto"
            }} />
        </BlurContentBox>
      </BlurBox>
      <BottomBox>
        <ContactFormBox
          onSubmit={handleSubmit}
          isMobile={isMobile()}>
          <ContactTitleText isMobile={isMobile()}>Contact us</ContactTitleText>
          <ContactSecondTitleText isMobile={isMobile()}>
            In order to better improve our products,we listen to your opinions.
          </ContactSecondTitleText>
          <ContactLabel htmlFor={"contact-email"} isMobile={isMobile()}>
            Email<span style={{ color: "#ED0000" }}>*</span>
          </ContactLabel>
          <ContactEmailInput
            id={"contact-email"}
            name={"email"}
            type={"email"}
            value={formData.email}
            onChange={handleValueChange}
            isMobile={isMobile()} />
          <ContactLabel htmlFor={"contact-content"} isMobile={isMobile()}>
            Content<span style={{ color: "#ED0000" }}>*</span>
          </ContactLabel>
          <ContactContentArea
            id={"contact-content"}
            name={"content"}
            value={formData.content}
            onChange={handleValueChange}
            isMobile={isMobile()} />
          <SubmitButton
            type={"submit"}
            isMobile={isMobile()}>
            Submit
          </SubmitButton>
        </ContactFormBox>
        <StoreBox isMobile={isMobile()}>
          <BottomLogo
            src={require("../assets/icon_logo.png")}
            isMobile={isMobile()} />
          <BottomAppNameText isMobile={isMobile()}>INNOVA</BottomAppNameText>
          <StoreLinkBox isMobile={isMobile()}>
            <AppleStoreImg
              src={require("../assets/img_app_store.png")}
              onClick={(e) => {
                e.preventDefault();
              }}
              isMobile={isMobile()} />
            <GoogleStoreImg
              src={require("../assets/img_google_play.png")}
              onClick={(e) => {
                e.preventDefault();
              }}
              isMobile={isMobile()} />
          </StoreLinkBox>
        </StoreBox>
        <CopyrightText isMobile={isMobile()}>
          Copyright © 2024 INNOVA. All rights reserved.
        </CopyrightText>
      </BottomBox>
    </Root>
  )
}

const Root = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #2C0066;
  padding-top: 48px;
`

const BarText = styled.div`
  font-size: ${props => props.isMobile ? "16px" : "38px"};
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
`

const TopLine = styled.div`
  height: 4px;
  background: linear-gradient(90deg, rgba(56, 152, 236, 1), rgba(203, 0, 61, 1));
  margin-top: 36px;
`

const TopLogo = styled.img`
  width: ${props => props.isMobile ? "48px" : "63px"};
  height: ${props => props.isMobile ? "48px" : "63px"};
`

const TopAppNameText = styled.div`
  font-size: ${props => props.isMobile ? "20px" : "40px"};
  color: #FFFFFF;
  font-weight: bold;
  margin-left: ${props => props.isMobile ? "15px" : "31px"};
`

const BlurBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isMobile ? "25px" : "51px"};
  margin-bottom: ${props => props.isMobile ? "160px" : "321px"};
`

const BlurBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(203, 164, 255, 0.25);
  filter: blur(400px);
`

const BlurContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleText = styled.div`
  line-height: ${props => props.isMobile ? "60px" : "140px"};
  font-size: ${props => props.isMobile ? "36px" : "88px"};
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
`

const IntroText = styled.div`
  font-size: ${props => props.isMobile ? "18px" : "36px"};
  color: #D9BCFF;
  font-weight: 400;
  text-align: center;
  margin-top: ${props => props.isMobile ? "50px" : "100px"};
  margin-right: 15px;
  margin-left: 15px;
`

const OverviewImg = styled.img`
  align-self: flex-start;
`

const BottomBox = styled.div`
  position: relative;
  background-color: #EAEBFF;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactFormBox = styled.form`
  width: ${props => props.isMobile ? "undefined" : "58%"};
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isMobile ? "240px" : "442px"};
  margin-right: 15px;
  margin-left: 15px;
`

const ContactTitleText = styled.div`
  font-size: ${props => props.isMobile ? "20px" : "40px"};
  color: #333333;
  font-weight: bold;
`

const ContactSecondTitleText = styled.div`
  font-size: ${props => props.isMobile ? "16px" : "32px"};
  color: #333333;
  font-weight: 400;
  margin-top: ${props => props.isMobile ? "22px" : "45px"};
  margin-bottom: ${props => props.isMobile ? "3px" : "7px"};
`

const ContactLabel = styled.label`
  line-height: ${props => props.isMobile ? "22px" : "44px"};
  font-size: ${props => props.isMobile ? "14px" : "24px"};
  color: #333333;
  font-weight: bold;
  margin-top: ${props => props.isMobile ? "24px" : "45px"};
`

const ContactEmailInput = styled.input`
  height: ${props => props.isMobile ? "36px" : "71px"};
  background: #FFFFFF;
  border: 1px solid #898989;
  outline-width: 0;
  font-size: ${props => props.isMobile ? "14px" : "24px"};
  color: #333333;
  padding-right: 12px;
  padding-left: 12px;
`

const ContactContentArea = styled.textarea`
  height: ${props => props.isMobile ? "81px" : "162px"};
  background: #FFFFFF;
  border: 1px solid #898989;
  outline-width: 0;
  font-size: ${props => props.isMobile ? "14px" : "24px"};
  color: #333333;
  padding: 12px;
  resize: none;
`

const SubmitButton = styled.button`
  align-self: flex-end;
  width: ${props => props.isMobile ? "84px" : "148px"};
  height: ${props => props.isMobile ? "40px" : "77px"};
  background: #6426B1;
  font-size: ${props => props.isMobile ? "16px" : "24px"};
  color: #FFFFFF;
  font-weight: 400;
  margin-top: ${props => props.isMobile ? "22px" : "55px"};
  cursor: pointer;
`

const CopyrightText = styled.div`
  font-size: ${props => props.isMobile ? "14px" : "26px"};
  color: #000000;
  font-weight: 400;
  margin-top: ${props => props.isMobile ? "67px" : "134px"};
  margin-bottom: ${props => props.isMobile ? "40px" : "67px"};
`

const StoreBox = styled.div`
  position: absolute;
  top: ${props => props.isMobile ? "-150px" : "-303px"};
  width: 86%;
  background: #6426B1;
  border-radius: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BottomLogo = styled.img`
  width: ${props => props.isMobile ? "55px" : "159px"};
  height: ${props => props.isMobile ? "55px" : "159px"};
  margin-top: ${props => props.isMobile ? "35px" : "117px"};
`

const BottomAppNameText = styled.div`
  font-size: ${props => props.isMobile ? "24px" : "48px"};
  color: #FFFFFF;
  font-weight: bold;
  margin-top: ${props => props.isMobile ? "15px" : "35px"};
`

const StoreLinkBox = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
  align-items: center;
  margin-top: ${props => props.isMobile ? "38px" : "76px"};
  margin-bottom: ${props => props.isMobile ? "63px" : "127px"};
`

const AppleStoreImg = styled.img`
  width: ${props => props.isMobile ? "163px" : "326px"};
  height: ${props => props.isMobile ? "47px" : "94px"};
  cursor: pointer;
`

const GoogleStoreImg = styled.img`
  width: ${props => props.isMobile ? "163px" : "326px"};
  height: ${props => props.isMobile ? "47px" : "94px"};
  margin-top: ${props => props.isMobile ? "20px" : "0px"};
  margin-left: ${props => props.isMobile ? "0" : "152px"};
  cursor: pointer;
`





