import styled from "styled-components";

export default function FlexBox({ children, onClick, style }) {

  return (
    <Box
      onClick={onClick}
      style={style}>
      {children}
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
