import React from 'react'

export default function Privacy() {

  return (
    <div className='w-full flex flex-col items-center px-4 text-black pt-10'>
        <h2 className='text-black text-xl xl:text-[40px]/[56px] 2xl:text-[40px]/[56px] font-bold'>Privacy Policy</h2>
        <div className='w-full max-w-[1000px] my-11 text-black'>
            <div className='text-black'>
                <p>Update Date: July 9, 2024</p>
                <p>Effective Date: July 9, 2024</p>
            </div>
            <div className='space-y-7 mt-5'>
                <p>INNOVA fully respects your privacy. This policy covers the processing of personal data by INNOVA, which is committed to protecting the privacy of your personal information whilst striving to provide the very best user experience. We want our websites to be safe and enjoyable for everyone.</p>
                <p>The operations of INNOVA are in accordance with the European Union’s General Data Protection Regulation (GDPR), effective May 25, 2018. INNOVA has made the GDPR a priority, and we are and have always been fully aligned with the regulation’s intended result: the protection of your privacy and personal data.</p>
            </div>

            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>1. What data do we collect and how do we use it?</h2>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(1)Account information.</h3>
                    <p>When you participate in, access, purchase or sign up to any of our services, activities or online content, such as subscriptions, newsletters, surveys, competitions or events, we receive personal information about you which we use to be able to provide these services. This may consist of data including your name, email address, postal address and telephone number. You will be able to select how we use your information for marketing purposes and you can update your marketing preferences at any time. INNOVA stores, processes and maintains files that you create and/or upload using the Services (as well as previous versions of your files), including INNOVA charts that you create, sharing lists, and other data related to your account in order to provide the service to you.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(2)Usage information.</h3>
                    <p>We collect information related to how you use the Services, including actions you take when using our Services (like editing, viewing, and sharing Your Stuff, changing themes, activating software license, and logging in and out of your account). We use this information to improve our Services, develop new services and features, and protect you as well as other INNOVA users.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(3)Device information. </h3>
                    <p>We also collect information from and about the devices you use to access the Services. This includes things like IP addresses, system language, the type of browser and device you use, the version of our software, the web page you visited before coming to our sites, and identifiers associated with your devices. Your devices (depending on their settings) may also transmit location information to the Services.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(4)Uploaded Files.</h3>
                    <p>All uploaded images or data are secure and will be dealt with confidentially, data will be transferred with SSL/TLS-encrypted and only used to perform requested image processing. When you use the AI face swap function, we will also collect the facial data in your picture and replace the corresponding data in other templates according to your instructions. All images you upload will be permanently deleted automatically from the server 1 hour after you last edited or closed the image processing page. Meanwhile, we will not share your data with any third parties.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(5)Email Marketing.</h3>
                    <p>To send emails and other communications. Transactional: As part of our services, we provide users with certain communications and updates, We may send you service, marketing, technical and other administrative communications, such as communications about your account, our Service offerings, changes to the Services, and important Services-related notices, such as security and fraud notices. We consider these communications as part of our Services to you.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>2. Legally process personal data</h2>
                <h3 className='text-base'>We will not share your personal data with other parties except as provided below:</h3>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(1)Personal information.</h3>
                    <p>We may share personal information with Service Providers. We provide certain Services and Software through Service Providers. We may share your personal identification information with such Service Providers to fulfill orders, deliver packages, send postal or email, administer contests or sweepstakes, manage customer lists, analyze data, provide marketing assistance, process credit card and other payments, operate the Website, troubleshoot, and provide customer service. We will also share your information with Service Providers to accomplish our administrative tasks.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(2)Disclose personal data.</h3>
                    <p>We may disclose the User’s personal data if INNOVA is under a duty to disclose or share such personal data in order to comply with any legal obligation, or in order to protect the rights, property or safety of its business, its customers or others.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(3)Transmit personal information.</h3>
                    <p>
                        We may also transmit your personal information with companies that help us to run our business by processing personal information on behalf of us for the purposes identified above. Such companies include providers of payment processing services, data analysis services providers, fraud monitoring and prevention providers, email delivery service providers, social media, and other marketing platforms and service providers.
                    </p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(4)Outside the European Union. </h3>
                    <p>The collected personal data may be transferred by INNOVA to recipients which are located outside the European Union and such destinations may not have laws which protect the User’s personal data to the same extent as in the European Union. INNOVA ensures that the User’s personal data processed by INNOVA or by its suppliers and partners operating outside the European Union are treated securely and are protected against unauthorized access, loss or destruction, unlawful processing and any processing which is inconsistent with the purposes set out in this privacy policy.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(5)Third party track tools. </h3>
                    <p>We may use third party track tools (such as Adwords, Bing, bugsplat, Criteo, disqus, fabric, Facebook, FireBase, Flurry, Google Analytics, Hotjar, LinkedIn, Pardot, Quora, JPUSH) to track the information on how users use the products and websites, such information will be stored in third party servers. Such third party track tools may send the data analysis report for INNOVA’s above mentioned purpose.</p>
                    <p>Store information. We may store the collected information on the servers leased from providers of third party server service.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>3. Storage and retention of data</h2>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(1)User Controls.</h3>
                    <p>You can access, amend, download, and delete your personal information by logging into your INNOVA account and going to your account settings page.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(2)Retention.</h3>
                    <p>When you sign up for an account with us, we’ll retain information associated with your account for as long as your account is in existence or as long as we need it to provide you the Services. If you delete your account, we will initiate deletion of this information immediately. But please note: (1) there might be some latency in deleting this information from our servers and back-up storage; and (2) we may retain this information if necessary to comply with our legal obligations, resolve disputes, or enforce our agreements. Other information will be retained until you ask us to delete it.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>4. Security</h2>
                <div className='space-y-4'>
                    <p>We are committed to protecting the personal data we hold. In accordance with GDPR, we adopt appropriate technical and organisational measures to help prevent unauthorised access to your information and ensure data is held securely. We also require any third parties to whom we may transfer personal data to have appropriate security measures in place. We ask third parties to complete Data Security Questionnaires so we can be sure they are a safe place for your data.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>5. Your Right to Control and Access Your Information</h2>
                <h3 className='text-base'>You have control over your personal information and how it is collected, used, and shared. For example, you have a right to:</h3>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(1)Access and take your data.</h3>
                    <p>You can download copies of Your Stuff from our website. You can also ask us for a copy of personal data you provided to us.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(2)Change or correct personal data.</h3>
                    <p>You can manage your account and the content contained in it, as well as edit some of your profile details, through your account settings page.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(3)Delete your entire account.</h3>
                    <p>
                    You can delete your INNOVA account, as well as any information related to it once for all, through your account settings page.
                    </p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(4)Unsubscribe from our newsletters.</h3>
                    <p>Simply click any ‘Unsubscribe’ link in any marketing email you received to make your email address deleted from our mailing list.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(5)Your right to withdraw consent.</h3>
                    <p>Whenever you have given us your consent to use your personal data, you have the right to change your mind at any time and withdraw that consent.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(6)Disposal of online heritage.</h3>
                    <p>The User has the right to define general or specific instructions regarding storage, deletion and use of its personal data after death.</p>
                    <p>If you would like to exercise any of these rights, please contact us as set forth below. We will process such requests in accordance with applicable laws. To protect your privacy, we will take steps to verify your identity before fulfilling your request, such as by requiring you to submit your request via your account.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>6. Appendix: Permission application and application scenarios</h2>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(1)Network access permission (Android).</h3>
                    <p>Access network information,Request background service.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(2)Phone State.</h3>
                    <p>Read phone state,Read phone state, IMEI and IMSI information of device to identify users, prevent phishing websites and Trojan viruses, and perform user data statistics (installs, active users).</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(3)Storage permissions(read and write).</h3>
                    <p>Read and write external storage,Read local pictures, videos and other media files to help users upload files.</p>
                </div>
                <div className='space-y-4'>
                    <h3 className='text-xl font-bold'>(4)Camera permission.</h3>
                    <p>Allow the use of the camera,Used to take pictures, record videos, scan QR code and other functions.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>7. Children’s information</h2>
                <div className='space-y-4'>
                    <p>The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal information from children. If you learn that your child has provided us with personal information without your consent, you may contact us as set forth below. If we learn that we have collected a child’s personal information in violation of applicable law, we will promptly take steps to delete such information and terminate the child’s account.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>8. Policy changes</h2>
                <div className='space-y-4'>
                    <p>INNOVA may change this Policy from time to time as laws, regulations, industry standards, and/or our business evolves. We will post the changes to this page and encourage you to review our Policy periodically to stay informed. If we make changes that materially alter your privacy rights, INNOVA will provide additional notice, such as via email or through the Services.</p>
                    <p>If you disagree with the changes to this Policy, you should deactivate your Services Account. Contact your Account administrator at innovappltd@gmail.com if you wish to request the removal of your personal data under their control.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>9. Disclaimer</h2>
                <div className='space-y-4'>
                    <p>INNOVA does not assume any responsibility for network service interruptions or other defects caused by force majeure (as the case may be controlled, including, but not limited to acts of God, flood, war, terrorism, fire, riot, rebellion, civil commotion, strike, lockout, other industrial action, the general failure of electricity or other supply, technical failure, accidental or mechanical or electrical breakdown, computer failure or failure of any money transmission system, embargo, labor dispute and changes in any existing or future laws, ordinances, regulations, or any existing or future act of governmental activity or the like.) or reasons beyond INNOVA’s control, but will do its best to reduce the losses and impacts caused to users and ensure its stability and safety.</p>
                </div>
            </div>
            <div className='mt-8 space-y-7'>
                <h2 className='text-2xl font-bold'>10. Contact us</h2>
                <div className='space-y-4'>
                    <p>If you have any questions about this Privacy Policy or our privacy practices, or if you wish to submit a request to exercise your rights as detailed in this Privacy Policy, please contact our Data Protection Officer. If they can’t answer your question, you have the right to contact your local data protection supervisory authority.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
